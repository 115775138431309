import { default as delete_45accountkhsOawvHkIMeta } from "/opt/render/project/src/.app/pages/auth/delete-account.vue?macro=true";
import { default as login5o6afqXVStMeta } from "/opt/render/project/src/.app/pages/auth/login.vue?macro=true";
import { default as recoverI8WJ7WZAF1Meta } from "/opt/render/project/src/.app/pages/auth/recover.vue?macro=true";
import { default as register0M6hx7DxadMeta } from "/opt/render/project/src/.app/pages/auth/register.vue?macro=true";
import { default as resetBU3Ton4EKKMeta } from "/opt/render/project/src/.app/pages/auth/reset.vue?macro=true";
import { default as indexqPlZP6X4DlMeta } from "/opt/render/project/src/.app/pages/index.vue?macro=true";
import { default as indexNYrLTUUQQPMeta } from "/opt/render/project/src/.app/pages/manage/categories/index.vue?macro=true";
import { default as subLUDg35zOCRMeta } from "/opt/render/project/src/.app/pages/manage/categories/sub.vue?macro=true";
import { default as follow_45up_45categoriesFosTD88yaRMeta } from "/opt/render/project/src/.app/pages/manage/follow-up-categories.vue?macro=true";
import { default as assetsuLnp4WOvlcMeta } from "/opt/render/project/src/.app/pages/manage/groups/[id]/assets.vue?macro=true";
import { default as channelss8ElkWDXErMeta } from "/opt/render/project/src/.app/pages/manage/groups/[id]/channels.vue?macro=true";
import { default as indexxueEGKzTY2Meta } from "/opt/render/project/src/.app/pages/manage/groups/[id]/index.vue?macro=true";
import { default as membersVpsId527qjMeta } from "/opt/render/project/src/.app/pages/manage/groups/[id]/members.vue?macro=true";
import { default as indexNHnzBQY0IQMeta } from "/opt/render/project/src/.app/pages/manage/groups/index.vue?macro=true";
import { default as my_45groupsa2ppBPeOPiMeta } from "/opt/render/project/src/.app/pages/manage/groups/my-groups.vue?macro=true";
import { default as iconsZDvm45Ar6sMeta } from "/opt/render/project/src/.app/pages/manage/icons.vue?macro=true";
import { default as active0AYxcTBfbEMeta } from "/opt/render/project/src/.app/pages/manage/incidents/active.vue?macro=true";
import { default as editorobgDAN1pymMeta } from "/opt/render/project/src/.app/pages/manage/incidents/editor.vue?macro=true";
import { default as galleryBtWTLcwFAOMeta } from "/opt/render/project/src/.app/pages/manage/incidents/gallery.vue?macro=true";
import { default as indexevJYvRMQ7vMeta } from "/opt/render/project/src/.app/pages/manage/incidents/index.vue?macro=true";
import { default as table7oe8sToq9oMeta } from "/opt/render/project/src/.app/pages/manage/incidents/table.vue?macro=true";
import { default as types9q9DeN1j0ZMeta } from "/opt/render/project/src/.app/pages/manage/incidents/types.vue?macro=true";
import { default as indexosNFIZquRCMeta } from "/opt/render/project/src/.app/pages/manage/index.vue?macro=true";
import { default as info_45sourcesSf74wI213uMeta } from "/opt/render/project/src/.app/pages/manage/info-sources.vue?macro=true";
import { default as notifications1HLlWO8ZbcMeta } from "/opt/render/project/src/.app/pages/manage/notifications.vue?macro=true";
import { default as contactsCdbublCqGcMeta } from "/opt/render/project/src/.app/pages/manage/profile/contacts.vue?macro=true";
import { default as indexa4smCGMYTEMeta } from "/opt/render/project/src/.app/pages/manage/profile/index.vue?macro=true";
import { default as password2accOA8CBWMeta } from "/opt/render/project/src/.app/pages/manage/profile/password.vue?macro=true";
import { default as profileFecxDTwiSUMeta } from "/opt/render/project/src/.app/pages/manage/profile.vue?macro=true";
import { default as recommendationstg0YQbEzCCMeta } from "/opt/render/project/src/.app/pages/manage/recommendations.vue?macro=true";
import { default as reports1yu4m5vUTPMeta } from "/opt/render/project/src/.app/pages/manage/reports.vue?macro=true";
import { default as risk_45levelsIzjNAjemhVMeta } from "/opt/render/project/src/.app/pages/manage/risk-levels.vue?macro=true";
import { default as safe_45zonesoCJsQIyJX8Meta } from "/opt/render/project/src/.app/pages/manage/safe-zones.vue?macro=true";
import { default as approvalsoh0twZcmC3Meta } from "/opt/render/project/src/.app/pages/manage/users/approvals.vue?macro=true";
import { default as indexJ8gcVlLFa3Meta } from "/opt/render/project/src/.app/pages/manage/users/index.vue?macro=true";
import { default as zonesGLWhgLKxLMMeta } from "/opt/render/project/src/.app/pages/manage/zones.vue?macro=true";
import { default as copyrightqYyMdXXjAfMeta } from "/opt/render/project/src/.app/pages/policies/copyright.vue?macro=true";
import { default as eulaidVSJvoPg7Meta } from "/opt/render/project/src/.app/pages/policies/eula.vue?macro=true";
import { default as privacyWXXRWDW3IQMeta } from "/opt/render/project/src/.app/pages/policies/privacy.vue?macro=true";
import { default as supportDh4TcyxSSFMeta } from "/opt/render/project/src/.app/pages/policies/support.vue?macro=true";
import { default as unauthorizedN63AC73v5SMeta } from "/opt/render/project/src/.app/pages/unauthorized.vue?macro=true";
export default [
  {
    name: delete_45accountkhsOawvHkIMeta?.name ?? "auth-delete-account",
    path: delete_45accountkhsOawvHkIMeta?.path ?? "/auth/delete-account",
    meta: delete_45accountkhsOawvHkIMeta || {},
    alias: delete_45accountkhsOawvHkIMeta?.alias || [],
    redirect: delete_45accountkhsOawvHkIMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/auth/delete-account.vue").then(m => m.default || m)
  },
  {
    name: login5o6afqXVStMeta?.name ?? "auth-login",
    path: login5o6afqXVStMeta?.path ?? "/auth/login",
    meta: login5o6afqXVStMeta || {},
    alias: login5o6afqXVStMeta?.alias || [],
    redirect: login5o6afqXVStMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: recoverI8WJ7WZAF1Meta?.name ?? "auth-recover",
    path: recoverI8WJ7WZAF1Meta?.path ?? "/auth/recover",
    meta: recoverI8WJ7WZAF1Meta || {},
    alias: recoverI8WJ7WZAF1Meta?.alias || [],
    redirect: recoverI8WJ7WZAF1Meta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/auth/recover.vue").then(m => m.default || m)
  },
  {
    name: register0M6hx7DxadMeta?.name ?? "auth-register",
    path: register0M6hx7DxadMeta?.path ?? "/auth/register",
    meta: register0M6hx7DxadMeta || {},
    alias: register0M6hx7DxadMeta?.alias || [],
    redirect: register0M6hx7DxadMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: resetBU3Ton4EKKMeta?.name ?? "auth-reset",
    path: resetBU3Ton4EKKMeta?.path ?? "/auth/reset",
    meta: resetBU3Ton4EKKMeta || {},
    alias: resetBU3Ton4EKKMeta?.alias || [],
    redirect: resetBU3Ton4EKKMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/auth/reset.vue").then(m => m.default || m)
  },
  {
    name: indexqPlZP6X4DlMeta?.name ?? "index",
    path: indexqPlZP6X4DlMeta?.path ?? "/",
    meta: indexqPlZP6X4DlMeta || {},
    alias: indexqPlZP6X4DlMeta?.alias || [],
    redirect: indexqPlZP6X4DlMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexNYrLTUUQQPMeta?.name ?? "manage-categories",
    path: indexNYrLTUUQQPMeta?.path ?? "/manage/categories",
    meta: indexNYrLTUUQQPMeta || {},
    alias: indexNYrLTUUQQPMeta?.alias || [],
    redirect: indexNYrLTUUQQPMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/categories/index.vue").then(m => m.default || m)
  },
  {
    name: subLUDg35zOCRMeta?.name ?? "manage-categories-sub",
    path: subLUDg35zOCRMeta?.path ?? "/manage/categories/sub",
    meta: subLUDg35zOCRMeta || {},
    alias: subLUDg35zOCRMeta?.alias || [],
    redirect: subLUDg35zOCRMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/categories/sub.vue").then(m => m.default || m)
  },
  {
    name: follow_45up_45categoriesFosTD88yaRMeta?.name ?? "manage-follow-up-categories",
    path: follow_45up_45categoriesFosTD88yaRMeta?.path ?? "/manage/follow-up-categories",
    meta: follow_45up_45categoriesFosTD88yaRMeta || {},
    alias: follow_45up_45categoriesFosTD88yaRMeta?.alias || [],
    redirect: follow_45up_45categoriesFosTD88yaRMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/follow-up-categories.vue").then(m => m.default || m)
  },
  {
    name: assetsuLnp4WOvlcMeta?.name ?? "manage-groups-id-assets",
    path: assetsuLnp4WOvlcMeta?.path ?? "/manage/groups/:id()/assets",
    meta: assetsuLnp4WOvlcMeta || {},
    alias: assetsuLnp4WOvlcMeta?.alias || [],
    redirect: assetsuLnp4WOvlcMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/groups/[id]/assets.vue").then(m => m.default || m)
  },
  {
    name: channelss8ElkWDXErMeta?.name ?? "manage-groups-id-channels",
    path: channelss8ElkWDXErMeta?.path ?? "/manage/groups/:id()/channels",
    meta: channelss8ElkWDXErMeta || {},
    alias: channelss8ElkWDXErMeta?.alias || [],
    redirect: channelss8ElkWDXErMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/groups/[id]/channels.vue").then(m => m.default || m)
  },
  {
    name: indexxueEGKzTY2Meta?.name ?? "manage-groups-id",
    path: indexxueEGKzTY2Meta?.path ?? "/manage/groups/:id()",
    meta: indexxueEGKzTY2Meta || {},
    alias: indexxueEGKzTY2Meta?.alias || [],
    redirect: indexxueEGKzTY2Meta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/groups/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: membersVpsId527qjMeta?.name ?? "manage-groups-id-members",
    path: membersVpsId527qjMeta?.path ?? "/manage/groups/:id()/members",
    meta: membersVpsId527qjMeta || {},
    alias: membersVpsId527qjMeta?.alias || [],
    redirect: membersVpsId527qjMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/groups/[id]/members.vue").then(m => m.default || m)
  },
  {
    name: indexNHnzBQY0IQMeta?.name ?? "manage-groups",
    path: indexNHnzBQY0IQMeta?.path ?? "/manage/groups",
    meta: indexNHnzBQY0IQMeta || {},
    alias: indexNHnzBQY0IQMeta?.alias || [],
    redirect: indexNHnzBQY0IQMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/groups/index.vue").then(m => m.default || m)
  },
  {
    name: my_45groupsa2ppBPeOPiMeta?.name ?? "manage-groups-my-groups",
    path: my_45groupsa2ppBPeOPiMeta?.path ?? "/manage/groups/my-groups",
    meta: my_45groupsa2ppBPeOPiMeta || {},
    alias: my_45groupsa2ppBPeOPiMeta?.alias || [],
    redirect: my_45groupsa2ppBPeOPiMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/groups/my-groups.vue").then(m => m.default || m)
  },
  {
    name: iconsZDvm45Ar6sMeta?.name ?? "manage-icons",
    path: iconsZDvm45Ar6sMeta?.path ?? "/manage/icons",
    meta: iconsZDvm45Ar6sMeta || {},
    alias: iconsZDvm45Ar6sMeta?.alias || [],
    redirect: iconsZDvm45Ar6sMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/icons.vue").then(m => m.default || m)
  },
  {
    name: active0AYxcTBfbEMeta?.name ?? "manage-incidents-active",
    path: active0AYxcTBfbEMeta?.path ?? "/manage/incidents/active",
    meta: active0AYxcTBfbEMeta || {},
    alias: active0AYxcTBfbEMeta?.alias || [],
    redirect: active0AYxcTBfbEMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/incidents/active.vue").then(m => m.default || m)
  },
  {
    name: editorobgDAN1pymMeta?.name ?? "manage-incidents-editor",
    path: editorobgDAN1pymMeta?.path ?? "/manage/incidents/editor",
    meta: editorobgDAN1pymMeta || {},
    alias: editorobgDAN1pymMeta?.alias || [],
    redirect: editorobgDAN1pymMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/incidents/editor.vue").then(m => m.default || m)
  },
  {
    name: galleryBtWTLcwFAOMeta?.name ?? "manage-incidents-gallery",
    path: galleryBtWTLcwFAOMeta?.path ?? "/manage/incidents/gallery",
    meta: galleryBtWTLcwFAOMeta || {},
    alias: galleryBtWTLcwFAOMeta?.alias || [],
    redirect: galleryBtWTLcwFAOMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/incidents/gallery.vue").then(m => m.default || m)
  },
  {
    name: indexevJYvRMQ7vMeta?.name ?? "manage-incidents",
    path: indexevJYvRMQ7vMeta?.path ?? "/manage/incidents",
    meta: indexevJYvRMQ7vMeta || {},
    alias: indexevJYvRMQ7vMeta?.alias || [],
    redirect: indexevJYvRMQ7vMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/incidents/index.vue").then(m => m.default || m)
  },
  {
    name: table7oe8sToq9oMeta?.name ?? "manage-incidents-table",
    path: table7oe8sToq9oMeta?.path ?? "/manage/incidents/table",
    meta: table7oe8sToq9oMeta || {},
    alias: table7oe8sToq9oMeta?.alias || [],
    redirect: table7oe8sToq9oMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/incidents/table.vue").then(m => m.default || m)
  },
  {
    name: types9q9DeN1j0ZMeta?.name ?? "manage-incidents-types",
    path: types9q9DeN1j0ZMeta?.path ?? "/manage/incidents/types",
    meta: types9q9DeN1j0ZMeta || {},
    alias: types9q9DeN1j0ZMeta?.alias || [],
    redirect: types9q9DeN1j0ZMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/incidents/types.vue").then(m => m.default || m)
  },
  {
    name: indexosNFIZquRCMeta?.name ?? "manage",
    path: indexosNFIZquRCMeta?.path ?? "/manage",
    meta: indexosNFIZquRCMeta || {},
    alias: indexosNFIZquRCMeta?.alias || [],
    redirect: indexosNFIZquRCMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/index.vue").then(m => m.default || m)
  },
  {
    name: info_45sourcesSf74wI213uMeta?.name ?? "manage-info-sources",
    path: info_45sourcesSf74wI213uMeta?.path ?? "/manage/info-sources",
    meta: info_45sourcesSf74wI213uMeta || {},
    alias: info_45sourcesSf74wI213uMeta?.alias || [],
    redirect: info_45sourcesSf74wI213uMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/info-sources.vue").then(m => m.default || m)
  },
  {
    name: notifications1HLlWO8ZbcMeta?.name ?? "manage-notifications",
    path: notifications1HLlWO8ZbcMeta?.path ?? "/manage/notifications",
    meta: notifications1HLlWO8ZbcMeta || {},
    alias: notifications1HLlWO8ZbcMeta?.alias || [],
    redirect: notifications1HLlWO8ZbcMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/notifications.vue").then(m => m.default || m)
  },
  {
    name: profileFecxDTwiSUMeta?.name ?? undefined,
    path: profileFecxDTwiSUMeta?.path ?? "/manage/profile",
    meta: profileFecxDTwiSUMeta || {},
    alias: profileFecxDTwiSUMeta?.alias || [],
    redirect: profileFecxDTwiSUMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/profile.vue").then(m => m.default || m),
    children: [
  {
    name: contactsCdbublCqGcMeta?.name ?? "manage-profile-contacts",
    path: contactsCdbublCqGcMeta?.path ?? "contacts",
    meta: contactsCdbublCqGcMeta || {},
    alias: contactsCdbublCqGcMeta?.alias || [],
    redirect: contactsCdbublCqGcMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/profile/contacts.vue").then(m => m.default || m)
  },
  {
    name: indexa4smCGMYTEMeta?.name ?? "manage-profile",
    path: indexa4smCGMYTEMeta?.path ?? "",
    meta: indexa4smCGMYTEMeta || {},
    alias: indexa4smCGMYTEMeta?.alias || [],
    redirect: indexa4smCGMYTEMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/profile/index.vue").then(m => m.default || m)
  },
  {
    name: password2accOA8CBWMeta?.name ?? "manage-profile-password",
    path: password2accOA8CBWMeta?.path ?? "password",
    meta: password2accOA8CBWMeta || {},
    alias: password2accOA8CBWMeta?.alias || [],
    redirect: password2accOA8CBWMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/profile/password.vue").then(m => m.default || m)
  }
]
  },
  {
    name: recommendationstg0YQbEzCCMeta?.name ?? "manage-recommendations",
    path: recommendationstg0YQbEzCCMeta?.path ?? "/manage/recommendations",
    meta: recommendationstg0YQbEzCCMeta || {},
    alias: recommendationstg0YQbEzCCMeta?.alias || [],
    redirect: recommendationstg0YQbEzCCMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/recommendations.vue").then(m => m.default || m)
  },
  {
    name: reports1yu4m5vUTPMeta?.name ?? "manage-reports",
    path: reports1yu4m5vUTPMeta?.path ?? "/manage/reports",
    meta: reports1yu4m5vUTPMeta || {},
    alias: reports1yu4m5vUTPMeta?.alias || [],
    redirect: reports1yu4m5vUTPMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/reports.vue").then(m => m.default || m)
  },
  {
    name: risk_45levelsIzjNAjemhVMeta?.name ?? "manage-risk-levels",
    path: risk_45levelsIzjNAjemhVMeta?.path ?? "/manage/risk-levels",
    meta: risk_45levelsIzjNAjemhVMeta || {},
    alias: risk_45levelsIzjNAjemhVMeta?.alias || [],
    redirect: risk_45levelsIzjNAjemhVMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/risk-levels.vue").then(m => m.default || m)
  },
  {
    name: safe_45zonesoCJsQIyJX8Meta?.name ?? "manage-safe-zones",
    path: safe_45zonesoCJsQIyJX8Meta?.path ?? "/manage/safe-zones",
    meta: safe_45zonesoCJsQIyJX8Meta || {},
    alias: safe_45zonesoCJsQIyJX8Meta?.alias || [],
    redirect: safe_45zonesoCJsQIyJX8Meta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/safe-zones.vue").then(m => m.default || m)
  },
  {
    name: approvalsoh0twZcmC3Meta?.name ?? "manage-users-approvals",
    path: approvalsoh0twZcmC3Meta?.path ?? "/manage/users/approvals",
    meta: approvalsoh0twZcmC3Meta || {},
    alias: approvalsoh0twZcmC3Meta?.alias || [],
    redirect: approvalsoh0twZcmC3Meta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/users/approvals.vue").then(m => m.default || m)
  },
  {
    name: indexJ8gcVlLFa3Meta?.name ?? "manage-users",
    path: indexJ8gcVlLFa3Meta?.path ?? "/manage/users",
    meta: indexJ8gcVlLFa3Meta || {},
    alias: indexJ8gcVlLFa3Meta?.alias || [],
    redirect: indexJ8gcVlLFa3Meta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/users/index.vue").then(m => m.default || m)
  },
  {
    name: zonesGLWhgLKxLMMeta?.name ?? "manage-zones",
    path: zonesGLWhgLKxLMMeta?.path ?? "/manage/zones",
    meta: zonesGLWhgLKxLMMeta || {},
    alias: zonesGLWhgLKxLMMeta?.alias || [],
    redirect: zonesGLWhgLKxLMMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/manage/zones.vue").then(m => m.default || m)
  },
  {
    name: copyrightqYyMdXXjAfMeta?.name ?? "policies-copyright",
    path: copyrightqYyMdXXjAfMeta?.path ?? "/policies/copyright",
    meta: copyrightqYyMdXXjAfMeta || {},
    alias: copyrightqYyMdXXjAfMeta?.alias || [],
    redirect: copyrightqYyMdXXjAfMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/policies/copyright.vue").then(m => m.default || m)
  },
  {
    name: eulaidVSJvoPg7Meta?.name ?? "policies-eula",
    path: eulaidVSJvoPg7Meta?.path ?? "/policies/eula",
    meta: eulaidVSJvoPg7Meta || {},
    alias: eulaidVSJvoPg7Meta?.alias || [],
    redirect: eulaidVSJvoPg7Meta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/policies/eula.vue").then(m => m.default || m)
  },
  {
    name: privacyWXXRWDW3IQMeta?.name ?? "policies-privacy",
    path: privacyWXXRWDW3IQMeta?.path ?? "/policies/privacy",
    meta: privacyWXXRWDW3IQMeta || {},
    alias: privacyWXXRWDW3IQMeta?.alias || [],
    redirect: privacyWXXRWDW3IQMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/policies/privacy.vue").then(m => m.default || m)
  },
  {
    name: supportDh4TcyxSSFMeta?.name ?? "policies-support",
    path: supportDh4TcyxSSFMeta?.path ?? "/policies/support",
    meta: supportDh4TcyxSSFMeta || {},
    alias: supportDh4TcyxSSFMeta?.alias || [],
    redirect: supportDh4TcyxSSFMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/policies/support.vue").then(m => m.default || m)
  },
  {
    name: unauthorizedN63AC73v5SMeta?.name ?? "unauthorized",
    path: unauthorizedN63AC73v5SMeta?.path ?? "/unauthorized",
    meta: unauthorizedN63AC73v5SMeta || {},
    alias: unauthorizedN63AC73v5SMeta?.alias || [],
    redirect: unauthorizedN63AC73v5SMeta?.redirect,
    component: () => import("/opt/render/project/src/.app/pages/unauthorized.vue").then(m => m.default || m)
  }
]