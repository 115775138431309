import type { QueryOptions } from '~/services/types'
import type { Recommendation } from '~/types'
import type { PostgrestError } from '@supabase/postgrest-js'
import { SupabaseClient } from '@supabase/supabase-js'

export declare abstract class IRecommendationsService {
  abstract getAll(options?: QueryOptions): Promise<Recommendation[]>

  abstract get(recommendationId: string): Promise<Recommendation | null>

  abstract update(recommendationId: string, data: any): Promise<Recommendation>

  delete(recommendationId: number): Promise<void>

  abstract create(model: any): Promise<Recommendation>
}

export class RecommendationsServiceImpl implements IRecommendationsService {
  private readonly supabase: SupabaseClient

  constructor(supabaseClient: SupabaseClient) {
    this.supabase = supabaseClient
  }

  async delete(recommendationId: number) {
    const { error } = await this.supabase
      .from('recommendation')
      .delete()
      .eq('id', recommendationId)
    if (error) throw error
  }

  async get(recommendationId: string): Promise<Recommendation | null> {
    const response = await this.supabase
      .from('recommendation')
      .select('*')
      .eq('id', recommendationId)
      .limit(1)
      .single()
    return response.data as Recommendation | null
  }

  async getAll(options?: QueryOptions): Promise<Recommendation[]> {
    const { data, error } = await this.supabase
      .from('recommendation')
      .select('*')

    if (error) throw error

    return data as unknown as Recommendation[]
  }

  async update(recommendationId: string, data: any): Promise<Recommendation> {
    let updateDTO: any = {}
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined) updateDTO[key] = data[key]
    })

    const {
      data: updateResponse,
      error: updateModelError,
    }: any & PostgrestError = await this.supabase
      .from('recommendation')
      .update(updateDTO as never)
      .eq('id', recommendationId)
      .select('*')

    if (updateModelError) throw updateModelError

    return updateResponse[0]
  }

  async create(model: any): Promise<Recommendation> {
    let updateDTO: any = {}
    Object.keys(model).forEach((key) => {
      if (model[key] !== undefined) updateDTO[key] = model[key]
    })

    const {
      data: insertResponse,
      error: insertModelError,
    }: any & PostgrestError = await this.supabase
      .from('recommendation')
      .insert([updateDTO as never])
      .select()

    if (insertModelError) throw insertModelError

    return insertResponse[0]
  }
}
