import type { IncidentType } from '~/types'
import type { PostgrestError } from '@supabase/postgrest-js'
import { SupabaseClient } from '@supabase/supabase-js'

export declare abstract class IIncidentTypesService {
  abstract getAll(
    includeRelations: boolean,
    isActive?: boolean,
  ): Promise<IncidentType[]>

  abstract get(typeId: string): Promise<IncidentType | null>

  abstract update(typeId: string, data: any): Promise<IncidentType>

  abstract delete(typeId: number): Promise<void>

  abstract create(model: any): Promise<IncidentType>
}

export class IncidentTypesServiceImpl implements IIncidentTypesService {
  private readonly supabase: SupabaseClient

  constructor(supabaseClient: SupabaseClient) {
    this.supabase = supabaseClient
  }

  async delete(typeId: number) {
    const { error } = await this.supabase
      .from('incidentType')
      .delete()
      .eq('id', typeId)
    if (error) throw error
  }

  async get(typeId: string): Promise<IncidentType | null> {
    const response = await this.supabase
      .from('incidentType')
      .select('*')
      .eq('id', typeId)
      .limit(1)
      .single()
    return response.data as IncidentType | null
  }

  async getAll(
    includeRelations: boolean,
    isActive?: boolean,
  ): Promise<IncidentType[]> {
    let query = this.supabase
      .from('incidentType')
      .select(
        `*${
          includeRelations
            ? ', primaryIcon:primaryIconId(id, name), secondaryIcon:secondaryIconId(id, name), riskLevel:riskLevelId(id, name, hexColor), category:categoryId(name), subCategory:subCategoryId(name)'
            : ''
        }`,
      )

    if (isActive !== undefined) {
      query = query.filter('isActive', 'eq', isActive)
    }

    const { data, error } = await query.order('name')

    if (error) throw error

    return data as unknown as IncidentType[]
  }

  async update(typeId: string, data: any): Promise<IncidentType> {
    let updateDTO: any = {}
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined) updateDTO[key] = data[key]
    })

    const {
      data: updateResponse,
      error: updateModelError,
    }: any & PostgrestError = await this.supabase
      .from('incidentType')
      .update(updateDTO as never)
      .eq('id', typeId)
      .select('*')

    if (updateModelError) throw updateModelError

    return updateResponse[0]
  }

  async create(model: any): Promise<IncidentType> {
    let updateDTO: any = {}
    Object.keys(model).forEach((key) => {
      if (model[key] !== undefined) updateDTO[key] = model[key]
    })

    const {
      data: insertResponse,
      error: insertModelError,
    }: any & PostgrestError = await this.supabase
      .from('incidentType')
      .insert([updateDTO as never])
      .select()

    if (insertModelError) throw insertModelError

    return insertResponse[0]
  }
}
