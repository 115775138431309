import type { ChannelPreference, GroupAccount } from '~/types'
import * as OneSignal from '@onesignal/node-onesignal'
import axios from 'axios'

export interface OneSignalConfigs {
  appId: string
  apiKey: string
}

export interface NotificationResponse {
  notificationId: string
  payload: any
}

export async function getChannelSubscribedUsers(
  channels: ChannelPreference[],
): Promise<string[]> {
  const ids: string[] = []

  channels.forEach((channel: ChannelPreference) => {
    channel.group!.groupAccounts?.forEach((ga: GroupAccount) =>
      ids.push(ga.accountId!),
    )
  })

  return Array.from(new Set(ids))
}

export async function sendNotification(
  subscribers: string[],
  content: { en?: string; es?: string },
  name: string,
  incidentId?: number,
): Promise<NotificationResponse | undefined> {
  if (subscribers.length === 0) return

  const config = useRuntimeConfig()
  const onesignalConfig = config.onesignal as OneSignalConfigs

  const client = axios.create({
    baseURL: 'https://onesignal.com/api/v1',
    headers: {
      Authorization: `Basic ${onesignalConfig.apiKey}`,
      accept: 'application/json',
      'content-type': 'application/json',
    },
  })

  const payload: OneSignal.Notification = {
    app_id: onesignalConfig.appId,
    include_external_user_ids: subscribers,
    target_channel: 'push',
    isAndroid: true,
    isIos: true,
    headings: {
      en: `New Event: ${name}`,
      es: `Nuevo evento: ${name}`,
    },
    contents: {
      en: content.en ?? 'Alert',
      es: content.es ?? 'Alerta',
    },
    data: {
      incidentId: incidentId,
    },
  } as OneSignal.Notification

  const result = await client.post('/notifications', payload)

  return {
    notificationId: result.data.id,
    payload,
  }
}
