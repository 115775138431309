import type {QueryOptions} from "~/services/types";
import type {SubCategory} from "~/types";
import type {PostgrestError} from "@supabase/postgrest-js";
import {SupabaseClient} from "@supabase/supabase-js";

export declare abstract class ISubCategoriesService {
  abstract getAll(options?: QueryOptions): Promise<SubCategory[]>
  abstract get(categoryId: string): Promise<SubCategory | null>
  abstract update(
    categoryId: string,
    data: any
  ): Promise<SubCategory>
  delete(categoryId: number): Promise<void>
  abstract create(model: any): Promise<SubCategory>
}

export class SubCategoriesServiceImpl implements ISubCategoriesService {
  private readonly supabase: SupabaseClient;
  constructor(supabaseClient: SupabaseClient) {
    this.supabase = supabaseClient
  }
  async delete(categoryId: number){

    const { error } = await this.supabase.from('subCategory').delete().eq('id', categoryId);
    if(error) throw error;
  }

  async get(categoryId: string): Promise<SubCategory | null> {


    const response = await this.supabase
      .from('subCategory').select('*')
      .eq('id', categoryId)
      .limit(1)
      .single();
    return response.data as SubCategory | null;
  }

  async getAll(options?: QueryOptions): Promise<SubCategory[]> {

    const { data, error } = await this.supabase
      .from('subCategory')
      .select('*')

    if(error) throw error;

    return data as unknown as SubCategory[];
  }

  async update(categoryId: string, data: any): Promise<SubCategory> {


    let updateDTO: any = {};
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined) updateDTO[key] = data[key]
    })

    const {
      data: updateResponse,
      error: updateModelError
    }: any & PostgrestError = await this.supabase.from('subCategory').update(updateDTO as never)
      .eq('id', categoryId).select('*');

    if (updateModelError) throw updateModelError;

    return updateResponse[0];
  }

  async create(model: any): Promise<SubCategory> {


    let updateDTO: any = {};
    Object.keys(model).forEach((key) => {
      if (model[key] !== undefined) updateDTO[key] = model[key]
    })

    const {
      data: insertResponse,
      error: insertModelError
    }: any & PostgrestError = await this.supabase.from('subCategory').insert([updateDTO as never])
      .select();

    if (insertModelError) throw insertModelError;

    return insertResponse[0];
  }
}
