import type { QueryOptions } from "~/services/types";
import type {
  ChannelPreference,
  Notification,
  NotificationCreateDTO,
  UserNotification,
  UserNotificationSummary,
} from "~/types";
import { SupabaseClient } from "@supabase/supabase-js";
import { getChannelSubscribedUsers } from "~/server/utils";

export declare abstract class INotificationsService {
  abstract getAll(options?: QueryOptions): Promise<Notification[]>;
  abstract getChannels(notificationId: string): Promise<ChannelPreference[]>;
  abstract getRecipients(incidentId: string): Promise<UserNotification[]>;
  abstract getGroupRecipients(
    incidentId: string,
    groupId: string,
  ): Promise<UserNotificationSummary[]>;
  abstract get(notificationId: string): Promise<Notification | null>;
  delete(notificationId: number): Promise<void>;
  abstract create(model: NotificationCreateDTO): Promise<void>;
}

export class NotificationsServiceImpl implements INotificationsService {
  private readonly supabase: SupabaseClient;

  constructor(supabaseClient: SupabaseClient) {
    this.supabase = supabaseClient;
  }
  async delete(notificationId: number) {
    const { error } = await this.supabase
      .from("notification")
      .delete()
      .eq("id", notificationId);
    if (error) throw error;
  }

  async get(notificationId: string): Promise<Notification | null> {
    const response = await this.supabase
      .from("notification")
      .select("*")
      .eq("id", notificationId)
      .limit(1)
      .single();
    return response.data as Notification | null;
  }

  async getAll(options?: QueryOptions): Promise<Notification[]> {
    const { data, error } = await this.supabase.from("notification").select(
      "*",
    );

    if (error) throw error;

    return data as unknown as Notification[];
  }
  async getChannels(notificationId: string): Promise<ChannelPreference[]> {
    const { data: channelNotifications, error: notificationError } = await this
      .supabase
      .from("channelNotification")
      .select("*")
      .eq("notificationId", notificationId);
    if (notificationError) throw notificationError;
    const { data: channels, error: channelError } = await this.supabase
      .from("channelPreference")
      .select("*, group:groupId(*, groupAccounts:groupAccount(*))")
      .in(
        "id",
        channelNotifications.map((cn) => cn.channelId),
      );
    if (channelError) throw channelError;
    return channels as unknown as ChannelPreference[];
  }

  async getRecipients(incidentId: string): Promise<UserNotification[]> {
    const { data, error } = await this.supabase
      .from("userNotification")
      .select("*, account:userId(*), notification:notificationId(*), ")
      .eq("notificationId.incidentId", incidentId);
    if (error) throw error;
    return data as unknown as UserNotification[];
  }

  async getGroupRecipients(
    incidentId: string,
    groupId: string,
  ): Promise<UserNotificationSummary[]> {
    const { data, error } = await this.supabase
      .from("vwGroupIncidentNotificationStatus")
      .select()
      .eq("incidentId", incidentId)
      .eq("groupId", groupId);

    if (error) throw error;

    return data as unknown as UserNotificationSummary[];
  }

  async create(model: NotificationCreateDTO): Promise<void> {
    const { data: notification, error: notificationError } = await this.supabase
      .from("notification")
      .insert({
        content: {
          name: model.name,
          link: model.content.link,
          contents: {
            en: model.content.en,
            es: model.content.es,
          },
        },
      } as never)
      .select()
      .single();
    if (notificationError) throw notificationError;

    const { data: channels, error: channelsError } = await this.supabase
      .from("channelPreference")
      .select("*, group:groupId(*, groupAccounts:groupAccount(*))")
      .in("groupId", model.groupIds)
      .eq("isDefault", true);

    if (channelsError) throw channelsError;

    const subscribers = await getChannelSubscribedUsers(channels);

    const { error: channelNotificationError } = await this.supabase
      .from("channelNotification")
      .insert(
        channels.map((channel) => ({
          channelId: channel.id,
          notificationId: notification.id,
        })) as never,
      );

    if (channelNotificationError) throw channelNotificationError;

    const { error: userNotificationError } = await this.supabase
      .from("userNotification")
      .insert(
        subscribers.map((userId) => ({
          userId: userId,
          notificationId: notification.id,
          read: false,
        })) as never,
      );

    if (userNotificationError) throw userNotificationError;

    if (model.publish) {
      const result = await useFetch(
        `/api/notifications/publish?notificationId=${notification.id}`,
      );
    }
  }
}
