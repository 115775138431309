import type {
  Category,
  Incident,
  IncidentSourceEntry,
  IncidentType,
  InfoSource,
  InvolvedPartyCategory,
  InvolvedPartyType,
  Site,
  SourceCategory,
  SourceSubCategory,
  SubCategory,
  Victim,
  VictimSeverity,
  VictimType,
} from "~/types";
import type { PostgrestError } from "@supabase/postgrest-js";
import { SupabaseClient } from "@supabase/supabase-js";
import { useUserSession } from "~/stores/userSession";
import { startOfDay, subDays } from "date-fns";
import type { IncidentWeatherData } from '~/types/openweatherapi';

interface IncidentFilter {
  incidentTypes?: IncidentType[];
  categories?: Category[];
  subCategories?: SubCategory[];
  hasSources?: boolean;
  sources?: InfoSource[];
  sourceCategories?: SourceCategory[];
  sourceSubCategories?: SourceSubCategory[];
  hasFollowUp?: boolean;
  severity?: VictimSeverity[];
  victims?: Victim[];
  victimTypes?: VictimType[];
  involvedPartyTypes?: InvolvedPartyType[];
  involvedPartyCategories?: InvolvedPartyCategory[];
  sites?: Site[];
  victimTotal?: number;
  hasVictims?: boolean;
  eventFrom: Date;
  eventTo?: Date;
}


export const filterSelect = `
  *,
  type:typeId!inner(id, name, primaryIcon:primaryIconId(name, url),secondaryIcon:secondaryIconId(name, url),riskLevel:riskLevelId(*), category:categoryId(name), subCategory:subCategoryId(name))
`;

export declare abstract class IIncidentsService {
  abstract getAllIncidentsByFilter(filter: IncidentFilter): Promise<Incident[]>;

  abstract getAllIncidentMediaByFilter(filter: IncidentFilter): Promise<Incident[]>

  abstract getAll(customSelect?: string): Promise<Incident[]>;

  abstract getAllUnsourced(detailed?: boolean): Promise<Incident[]>;

  abstract getUnsourcedCount(): Promise<number>;

  abstract getOpenCount(): Promise<number>;

  abstract get(
    incidentId: string,
    includeRelations: boolean,
  ): Promise<Incident | null>;

  abstract update(
    incidentId: string,
    data: any,
    includeRelations: boolean,
  ): Promise<Incident>;

  abstract delete(incidentId: number): Promise<void>;

  abstract create(model: any, includeRelations: boolean): Promise<Incident>;

  abstract getSourceEntries(incidentId: number): Promise<IncidentSourceEntry[]>;

  abstract createSourceEntry(
    incidentId: number,
    model: any,
  ): Promise<IncidentSourceEntry>;

  abstract updateSourceEntry(
    entryId: string,
    model: any,
  ): Promise<IncidentSourceEntry>;

  abstract deleteSourceEntry(entryId: string): Promise<void>;

  abstract close(incidentId: number): Promise<Incident>;
  abstract reopen(incidentId: number): Promise<Incident>;

  abstract getActiveIncidents(queryOverride?: string): Promise<Incident[]>;

  abstract saveWeatherData(incident: IncidentWeatherData): Promise<IncidentWeatherData>;
}

export class IncidentsServiceImpl implements IIncidentsService {
  private readonly supabase: SupabaseClient;

  constructor(supabaseClient: SupabaseClient) {
    this.supabase = supabaseClient;
  }

  async getAllIncidentsByFilter(filter: IncidentFilter) {
    const typeIds = filter?.incidentTypes?.map((type) => type.id) ?? [];
    const categoryIds = filter?.categories?.map((category) => category.id) ??
      [];
    const subCategoryIds =
      filter?.subCategories?.map((subCategory) => subCategory.id) ?? [];
    const sourceIds = filter?.sources?.map((source) => source.id) ?? [];
    const sourceCategoryIds =
      filter?.sourceCategories?.map((category) => category.id) ?? [];
    const sourceSubCategoryIds =
      filter?.sourceSubCategories?.map((subCategory) => subCategory.id) ?? [];
    const victimTypeIds = filter?.victimTypes?.map((type) => type.id) ?? [];
    const involvedPartyTypeIds =
      filter?.involvedPartyTypes?.map((type) => type.id) ?? [];
    const involvedPartyCategoryIds =
      filter?.involvedPartyCategories?.map((type) => type.id) ?? [];
    const siteIds = filter?.sites?.map((site) => site.id) ?? [];

    const followUpSelect = `, followUp!inner(*${
      victimTypeIds.length > 0 ? ", victim!inner(id, victimTypeId)" : ""
    }${
      involvedPartyTypeIds.length > 0 || involvedPartyCategoryIds.length > 0
        ? ", involvedParty!inner(id, involvedPartyType!inner(id, name, categoryId))"
        : ""
    }${siteIds.length > 0 ? ", followUpSite!inner(id, siteId)" : ""}
    )`;
    const sourceSelect =
      `, sources:incidentSourceEntry!inner(*, source:sourceId(id, name, subCategory:subCategoryId(id, name, category:categoryId(id, name))))`;

    const customSelect = filterSelect +
      (filter.hasFollowUp ? followUpSelect : "") +
      (filter.hasSources ? sourceSelect : "");
    let query = this.supabase.from("incident").select(customSelect);

    if (typeIds.length > 0) {
      query = query.in("typeId", typeIds);
    }

    if (categoryIds.length > 0) {
      query = query.in("type.categoryId", categoryIds);
    }

    if (subCategoryIds.length > 0) {
      query = query.in("type.subCategoryId", subCategoryIds);
    }

    if (filter.eventFrom) {
      query = query.gte(
        "eventStart",
        filter.eventFrom instanceof Date
          ? filter.eventFrom.toUTCString()
          : filter.eventFrom,
      );
    }

    if (filter.eventTo) {
      query = query.lte(
        "eventStart",
        filter.eventTo instanceof Date
          ? filter.eventTo.toUTCString()
          : filter.eventTo,
      );
    }

    if (filter.hasSources) {
      if (sourceCategoryIds.length > 0) {
        query = query.in(
          "sources.source.subCategory.categoryId",
          sourceCategoryIds,
        );
      }
      if (sourceSubCategoryIds.length > 0) {
        query = query.in("sources.source.subCategoryId", sourceSubCategoryIds);
      }
      if (sourceIds.length > 0) {
        query = query.in("sources.sourceId", sourceIds);
      }
    }

    if (filter.hasFollowUp) {
      if (filter.severity && filter.severity.length > 0) {
        query = query.in("followUp.severity", filter.severity);
      }
      if (filter.hasVictims !== undefined) {
        query = query[filter.hasVictims ? "gt" : "eq"](
          "followUp.victimTotal",
          0,
        );
      }
      if (filter.victimTotal) {
        query = query.eq("followUp.victimTotal", filter.victimTotal);
      }
      if (victimTypeIds.length > 0) {
        query = query.in("followUp.victim.victimTypeId", victimTypeIds);
      }
      if (involvedPartyTypeIds.length > 0) {
        query = query.in(
          "followUp.involvedParty.involvedPartyTypeId",
          involvedPartyTypeIds,
        );
      }
      if (involvedPartyCategoryIds.length > 0) {
        query = query.in(
          "followUp.involvedParty.involvedPartyType.categoryId",
          involvedPartyCategoryIds,
        );
      }
      if (siteIds.length > 0) {
        query = query.in("followUp.followUpSite.siteId", siteIds);
      }
    }

    query = query.order("eventStart", { ascending: false });

    const { data: queryResponse, error: queryError } = await query;

    if (queryError) throw queryError;

    return queryResponse as unknown as Incident[];
  }
  async getAllIncidentMediaByFilter(filter: IncidentFilter) {
    const typeIds = filter?.incidentTypes?.map((type) => type.id) ?? [];
    const categoryIds = filter?.categories?.map((category) => category.id) ??
      [];
    const subCategoryIds =
      filter?.subCategories?.map((subCategory) => subCategory.id) ?? [];
    const sourceIds = filter?.sources?.map((source) => source.id) ?? [];
    const sourceCategoryIds =
      filter?.sourceCategories?.map((category) => category.id) ?? [];
    const sourceSubCategoryIds =
      filter?.sourceSubCategories?.map((subCategory) => subCategory.id) ?? [];
    const victimTypeIds = filter?.victimTypes?.map((type) => type.id) ?? [];
    const involvedPartyTypeIds =
      filter?.involvedPartyTypes?.map((type) => type.id) ?? [];
    const involvedPartyCategoryIds =
      filter?.involvedPartyCategories?.map((type) => type.id) ?? [];
    const siteIds = filter?.sites?.map((site) => site.id) ?? [];

    const followUpSelect = `, followUp!inner(*, media:followUpMedia(*)${
      victimTypeIds.length > 0 ? ", victim!inner(id, victimTypeId)" : ""
    }${
      involvedPartyTypeIds.length > 0 || involvedPartyCategoryIds.length > 0
        ? ", involvedParty!inner(id, involvedPartyType!inner(id, name, categoryId))"
        : ""
    }${siteIds.length > 0 ? ", followUpSite!inner(id, siteId)" : ""}
    )`;
    const sourceSelect =
      `, sources:incidentSourceEntry!inner(*, source:sourceId(id, name, subCategory:subCategoryId(id, name, category:categoryId(id, name))))`;

    const customSelect = filterSelect +
      followUpSelect +
      (filter.hasSources ? sourceSelect : "");
    let query = this.supabase.from("incident").select(customSelect);

    if (typeIds.length > 0) {
      query = query.in("typeId", typeIds);
    }

    if (categoryIds.length > 0) {
      query = query.in("type.categoryId", categoryIds);
    }

    if (subCategoryIds.length > 0) {
      query = query.in("type.subCategoryId", subCategoryIds);
    }

    if (filter.eventFrom) {
      query = query.gte(
        "eventStart",
        filter.eventFrom instanceof Date
          ? filter.eventFrom.toUTCString()
          : filter.eventFrom,
      );
    }

    if (filter.eventTo) {
      query = query.lte(
        "eventStart",
        filter.eventTo instanceof Date
          ? filter.eventTo.toUTCString()
          : filter.eventTo,
      );
    }

    if (filter.hasSources) {
      if (sourceCategoryIds.length > 0) {
        query = query.in(
          "sources.source.subCategory.categoryId",
          sourceCategoryIds,
        );
      }
      if (sourceSubCategoryIds.length > 0) {
        query = query.in("sources.source.subCategoryId", sourceSubCategoryIds);
      }
      if (sourceIds.length > 0) {
        query = query.in("sources.sourceId", sourceIds);
      }
    }


      if (filter.severity && filter.severity.length > 0) {
        query = query.in("followUp.severity", filter.severity);
      }
      if (filter.hasVictims !== undefined) {
        query = query[filter.hasVictims ? "gt" : "eq"](
          "followUp.victimTotal",
          0,
        );
      }
      if (filter.victimTotal) {
        query = query.eq("followUp.victimTotal", filter.victimTotal);
      }
      if (victimTypeIds.length > 0) {
        query = query.in("followUp.victim.victimTypeId", victimTypeIds);
      }
      if (involvedPartyTypeIds.length > 0) {
        query = query.in(
          "followUp.involvedParty.involvedPartyTypeId",
          involvedPartyTypeIds,
        );
      }
      if (involvedPartyCategoryIds.length > 0) {
        query = query.in(
          "followUp.involvedParty.involvedPartyType.categoryId",
          involvedPartyCategoryIds,
        );
      }
      if (siteIds.length > 0) {
        query = query.in("followUp.followUpSite.siteId", siteIds);
      }

     query = query.not("followUp", "is", null);

     query = query.not("followUp.media", "is", null);

    query = query.order("eventStart", { ascending: false });

    const { data: queryResponse, error: queryError } = await query;

    if (queryError) throw queryError;

    return queryResponse as unknown as Incident[];
  }

  async getAllUnsourced(detailed?: boolean): Promise<Incident[]> {
    const { data, error } = await this.supabase
      .from("vwIncidentSummary")
      .select(detailed ? "*, type:typeId(id, name)" : "id, sourcecount")
      .eq("sourcecount", 0)
      .eq("isClosed", false)
      .eq("bypassSourceAlert", false)
      .order("eventStart", { ascending: false });
    if (error) throw error;
    return data as unknown as Incident[];
  }
  async getUnsourcedCount(): Promise<number> {
    const { count, error } = await this.supabase
      .from("vwIncidentSummary")
      .select("id", { count: "exact", head: true })
      .eq("sourcecount", 0)
      .eq("isClosed", false)
      .eq("bypassSourceAlert", false);

    if (error) throw error;
    return count ?? 0;
  }
  async getOpenCount(): Promise<number> {
    const { count, error } = await this.supabase
      .from("vwIncidentSummary")
      .select("id", { count: "exact", head: true })
      .eq("isClosed", false);

    if (error) throw error;
    return count ?? 0;
  }

  async delete(incidentId: number) {
    const { error } = await this.supabase
      .from("incident")
      .delete()
      .eq("id", incidentId);
    if (error) throw error;
  }

  async get(
    incidentId: string,
    includeRelations: boolean,
  ): Promise<Incident | null> {
    const q =
      "*, type:typeId(id, name, primaryIcon:primaryIconId(id, name), secondaryIcon:secondaryIconId(id, name), riskLevel:riskLevelId(id, name, hexColor), category:categoryId(name), subCategory:subCategoryId(name))";

    const response = await this.supabase
      .from("incident")
      .select(includeRelations ? q : "*")
      .eq("id", incidentId)
      .limit(1)
      .single();
    return response.data as Incident | null;
  }

  async getAll(customSelect?: string): Promise<Incident[]> {
    const { data, error } = await this.supabase
      .from("vwIncidentSummary")
      .select(customSelect ? customSelect : "*")
      .order("eventStart", { ascending: false });

    if (error) throw error;

    return data as unknown as Incident[];
  }

  async update(
    incidentId: string,
    data: any,
    includeRelations: boolean,
  ): Promise<Incident> {
    const q =
      "*, type:typeId(id, name, primaryIcon:primaryIconId(id, name), secondaryIcon:secondaryIconId(id, name), riskLevel:riskLevelId(id, name, hexColor), category:categoryId(name), subCategory:subCategoryId(name))";

    let updateDTO: any = {};
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined) updateDTO[key] = data[key];
    });

    const {
      data: updateResponse,
      error: updateModelError,
    }: any & PostgrestError = await this.supabase
      .from("incident")
      .update(updateDTO as never)
      .eq("id", incidentId)
      .select(includeRelations ? q : "*");

    if (updateModelError) throw updateModelError;

    return updateResponse[0];
  }

  async create(model: any, includeRelations: true): Promise<Incident> {
    const q =
      "*, type:typeId(name, primaryIcon:primaryIconId(id, name), secondaryIcon:secondaryIconId(id, name), riskLevel:riskLevelId(id, name, hexColor), category:categoryId(name), subCategory:subCategoryId(name))";

    let createDTO: any = {};
    Object.keys(model).forEach((key) => {
      if (model[key] !== undefined) createDTO[key] = model[key];
    });

    const {
      data: insertResponse,
      error: insertModelError,
    }: any & PostgrestError = await this.supabase
      .from("incident")
      .insert([createDTO as never])
      .select(includeRelations ? q : "*");

    if (insertModelError) throw insertModelError;

    return insertResponse[0];
  }

  async getSourceEntries(incidentId: number): Promise<IncidentSourceEntry[]> {
    const { data: entries, error: requestError } = await this.supabase
      .from("incidentSourceEntry")
      .select(
        "*, source:sourceId(*, subCategory:subCategoryId(id, name, category:categoryId(id, name)))",
      )
      .eq("incidentId", incidentId)
      .order("createdAt", { ascending: true });

    if (requestError) throw requestError;

    return entries as IncidentSourceEntry[];
  }

  async createSourceEntry(
    incidentId: number,
    model: any,
  ): Promise<IncidentSourceEntry> {
    let createDTO: any = {};
    Object.keys(model).forEach((key) => {
      if (model[key] !== undefined) createDTO[key] = model[key];
    });

    createDTO["incidentId"] = incidentId;

    const {
      data: insertResponse,
      error: insertModelError,
    }: any & PostgrestError = await this.supabase
      .from("incidentSourceEntry")
      .insert([createDTO as never])
      .select("*");

    if (insertModelError) throw insertModelError;

    return insertResponse[0];
  }

  async updateSourceEntry(
    entryId: string,
    model: any,
  ): Promise<IncidentSourceEntry> {
    let updateDTO: any = {};
    Object.keys(model).forEach((key) => {
      if (model[key] !== undefined) updateDTO[key] = model[key];
    });

    const {
      data: updateResponse,
      error: updateModelError,
    }: any & PostgrestError = await this.supabase
      .from("incidentSourceEntry")
      .update(updateDTO as never)
      .eq("id", entryId)
      .select("*");

    if (updateModelError) throw updateModelError;

    return updateResponse[0];
  }

  async deleteSourceEntry(entryId: string): Promise<void> {
    const { error } = await this.supabase
      .from("incidentSourceEntry")
      .delete()
      .eq("id", entryId);
    if (error) throw error;
  }

  async close(incidentId: number): Promise<Incident> {
    const q =
      "*, type:typeId(primaryIcon:primaryIconId(id, name), secondaryIcon:secondaryIconId(id, name), riskLevel:riskLevelId(id, name, hexColor), category:categoryId(name), subCategory:subCategoryId(name))";

    const session = useUserSession();

    const { data: closeResult, error: closeError } = await this.supabase
      .from("incident")
      .update({ isClosed: true, updatedBy: session.userId })
      .eq("id", incidentId)
      .select(q);

    if (closeError) throw closeError;

    return closeResult[0] as Incident;
  }
  async reopen(incidentId: number): Promise<Incident> {
    const q =
      "*, type:typeId(primaryIcon:primaryIconId(id, name), secondaryIcon:secondaryIconId(id, name), riskLevel:riskLevelId(id, name, hexColor), category:categoryId(name), subCategory:subCategoryId(name))";

    const session = useUserSession();

    const { data: openResult, error: openError } = await this.supabase
      .from("incident")
      .update({ isClosed: false, updatedBy: session.userId })
      .eq("id", incidentId)
      .select(q);

    if (openError) throw openError;

    return openResult[0] as Incident;
  }

  async getActiveIncidents(queryOverride?: string): Promise<Incident[]> {
    const now = new Date();
    const filterDate = startOfDay(subDays(now, 1));
    const { data, error } = await this.supabase
      .from("incident")
      .select(queryOverride ?? "*")
      .gte("eventStart", filterDate.toUTCString())
      // .lte('eventStart', filter.endDate)
      .order("eventStart", { ascending: false });
    if (error) throw error;
    return data as unknown as Incident[];
  }

  async saveWeatherData(incident: IncidentWeatherData): Promise<IncidentWeatherData> {
    const { data, error }= await this.supabase
        .from('incidentWeatherData')
        .insert([incident as never])
        .select('*')

    if(error) throw error;

    return data[0];
  }
}
