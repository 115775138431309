import type {
  Account,
  ChannelIncidentType,
  ChannelPreference,
  ChannelPreferenceCreateDTO,
  ChannelPreferenceUpdateDTO,
  Group,
  GroupAccount,
  GroupNotificationSummary,
  GroupSummary,
  Incident,
} from '~/types'
import type { PostgrestError } from '@supabase/postgrest-js'
import { fullIncidentQuery } from '~/utils/constants'
import { SupabaseClient } from '@supabase/supabase-js'

export declare abstract class IGroupsService {
  abstract getAll(forCurrentUser: boolean): Promise<GroupSummary[]>

  abstract get(groupId: string): Promise<Group | null>

  abstract getUserGroups(userId: string): Promise<Group[]>

  abstract update(groupId: string, data: any): Promise<Group>

  delete(groupId: number): Promise<void>

  abstract create(model: any): Promise<Group>

  abstract getMembers(groupId: string, limit?: number): Promise<Account[]>

  abstract removeMember(groupId: string, memberId: string): Promise<void>

  abstract addMember(groupId: string, userId: string): Promise<Account>

  abstract myGroupSummary(): Promise<GroupSummary[]>

  abstract getChannels(
    groupId: string,
    queryOverride?: string,
    limit?: number,
  ): Promise<ChannelPreference[]>

  abstract getChannel(
    channelId: string,
    queryOverride?: string,
  ): Promise<ChannelPreference | null>

  abstract updateChannel(
    data: ChannelPreferenceUpdateDTO,
  ): Promise<ChannelPreference>

  abstract deleteChannel(channelId: string): Promise<void>

  abstract createChannel(
    model: ChannelPreferenceCreateDTO,
  ): Promise<ChannelPreference>

  abstract incidents(groupId: string): Promise<Incident[]>
}

export class GroupsServiceImpl implements IGroupsService {
  private readonly supabase: SupabaseClient

  constructor(supabaseClient: SupabaseClient) {
    this.supabase = supabaseClient
  }

  async addMember(groupId: string, userId: string): Promise<Account> {
    const {
      data: insertResponse,
      error: insertModelError,
    }: any & PostgrestError = await this.supabase
      .from('groupAccount')
      .insert([
        {
          accountId: userId,
          groupId: groupId,
        },
      ] as never)
      .select('*, account:accountId(*)')

    if (insertModelError) throw insertModelError

    return (insertResponse[0] as GroupAccount).account!
  }

  async removeMember(groupId: string, memberId: string): Promise<void> {
    const { error } = await this.supabase
      .from('groupAccount')
      .delete()
      .eq('groupId', groupId)
      .eq('accountId', memberId)
    if (error) throw error
  }

  async getMembers(groupId: string, limit?: number): Promise<Account[]> {
    const { data: group, error } = await this.supabase
      .from('incidentGroup')
      .select('*, groupAccounts:groupAccount(account:accountId(*))')
      .eq('id', groupId)
      .limit(limit ?? 100, { foreignTable: 'groupAccount' })

    if (error) {
      console.error(error)
      return []
    }

    return (
      (group as any)[0]?.groupAccounts.map((u: GroupAccount) => u.account) ?? []
    )
  }

  async myGroupSummary(): Promise<GroupSummary[]> {
    const user = useSupabaseUser()
    const { data, error } = await this.supabase
      .from('incidentGroup')
      .select('id, groupAccounts:groupAccount!inner(*)')
      .eq('groupAccounts.accountId', user.value!.id)

    if (error) throw error

    const groupIds: string[] = data?.map((item: any) => item.id)

    if (groupIds.length === 0) return []

    const { data: viewData, error: viewError } = await this.supabase
      .from('vwGroupSummary')
      .select()
      .in('id', groupIds)

    if (viewError) throw viewError

    return viewData as unknown as GroupSummary[]
  }

  async delete(groupId: number) {
    const { error } = await this.supabase
      .from('incidentGroup')
      .delete()
      .eq('id', groupId)
    if (error) throw error
  }

  async get(groupId: string): Promise<Group | null> {
    const response = await this.supabase
      .from('incidentGroup')
      .select('*')
      .eq('id', groupId)
      .limit(1)
      .single()
    return response.data as Group | null
  }

  async getAll(forCurrentUser: boolean): Promise<GroupSummary[]> {
    if (forCurrentUser) return this.getGroupsForUser()

    const { data: viewData, error: viewError } = await this.supabase
      .from('vwGroupSummary')
      .select()

    if (viewError) throw viewError

    return viewData as unknown as GroupSummary[]
  }

  async getUserGroups(userId: string): Promise<Group[]> {
    const { data, error } = await this.supabase
      .from('groupAccount')
      .select(
        `
    *,
    group:groupId!inner(*)
  `,
      )
      .eq('accountId', userId)

    if (error) {
      throw error
    } else {
       const groups = data.map((groupAccount: any) => groupAccount.group);
    return groups;
    }
  }

  async getGroupsForUser() {
    const user = useSupabaseUser()

    const { data, error } = await this.supabase
      .from('account')
      .select('*, groupAccounts:groupAccount(*)')
      .eq('id', user.value!.id)

    if (error) {
      console.error(error)
      return []
    }

    return (data as any)[0].groupAccount ?? []
  }

  async update(categoryId: string, data: any): Promise<Group> {
    let updateDTO: any = {}
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined) updateDTO[key] = data[key]
    })

    const {
      data: updateResponse,
      error: updateModelError,
    }: any & PostgrestError = await this.supabase
      .from('incidentGroup')
      .update(updateDTO as never)
      .eq('id', categoryId)
      .select('*')

    if (updateModelError) {
      throw updateModelError
    }

    return updateResponse[0]
  }

  async create(model: any): Promise<Group> {
    let updateDTO: any = {}
    Object.keys(model).forEach((key) => {
      if (model[key] !== undefined) updateDTO[key] = model[key]
    })

    const {
      data: insertResponse,
      error: insertModelError,
    }: any & PostgrestError = await this.supabase
      .from('incidentGroup')
      .insert([updateDTO as never])
      .select()

    if (insertModelError) throw insertModelError

    return insertResponse[0]
  }

  async getChannels(
    groupId: string,
    queryOverride?: string,
    limit?: number,
  ): Promise<ChannelPreference[]> {
    const { data: channels, error } = await this.supabase
      .from('channelPreference')
      .select(queryOverride ? queryOverride : '*')
      .eq('groupId', groupId)
      .eq('isDefault', false)
      .limit(limit ?? 100)

    if (error) {
      console.error(error)
      return []
    }

    return channels as unknown as ChannelPreference[]
  }

  async getChannel(
    channelId: string,
    queryOverride?: string,
  ): Promise<ChannelPreference | null> {
    const response = await this.supabase
      .from('channelPreference')
      .select(queryOverride ? queryOverride : '*')
      .eq('id', channelId)
      .limit(1)
      .single()
    return response.data as ChannelPreference | null
  }

  async updateChannel(
    data: ChannelPreferenceUpdateDTO,
  ): Promise<ChannelPreference> {
    // Step 1: update incident types
    if (data.incidentTypeIds) {
      const typeList: ChannelIncidentType[] = data.incidentTypeIds.map(
        (incidentTypeId: number) =>
          ({
            channelId: data.channelId,
            incidentTypeId,
          } as ChannelIncidentType),
      )

      await this.updateChannelIncidentTypes(data.channelId, typeList)
    }

    // Step 2: update details if exists
    let detailUpdateDTO: any = {}
    let details: any | undefined = data.details
    if (details) {
      Object.keys(details).forEach((key: string) => {
        if (details && details[key] !== undefined)
          detailUpdateDTO[key] = details[key]
      })

      const {
        data: updateResponse,
        error: updateModelError,
      }: any & PostgrestError = await this.supabase
        .from('channelPreference')
        .update(detailUpdateDTO as never)
        .eq('id', data.channelId)
        .select('*, channelIncidentTypes:channelIncidentType(*)')

      if (updateModelError) throw updateModelError
      return updateResponse[0] as unknown as ChannelPreference
    }

    const channel = await this.getChannel(
      data.channelId,
      '*, channelIncidentTypes:channelIncidentType(*)',
    )
    if (!channel) throw new Error('unable to fetch channel')

    return channel
  }

  private async updateChannelIncidentTypes(
    channelId: string,
    list: ChannelIncidentType[],
  ) {
    if (list.length === 0) return

    // Delete all incident types first
    const { error: requestError } = await this.supabase
      .from('channelIncidentType')
      .delete()
      .eq('channelId', channelId)

    // Now we save them all
    const { error: insertError } = await this.supabase
      .from('channelIncidentType')
      .insert(list as never)
      .select('*, incidentType:incidentTypeId(*)')

    if (insertError) throw insertError
  }

  private async createChannelIncidentTypes(channelId: string, list: number[]) {
    if (list.length === 0) return []

    const items: ChannelIncidentType[] = list.map(
      (incidentTypeId) =>
        ({
          channelId,
          incidentTypeId,
        } as ChannelIncidentType),
    )

    const { data: insertResponse, error: insertError } = await this.supabase
      .from('channelIncidentType')
      .insert(items as never)
      .select('*, incidentType:incidentTypeId(*)')

    if (insertError) throw insertError

    return insertResponse
  }

  async deleteChannel(channelId: string): Promise<void> {
    const { error } = await this.supabase
      .from('channelPreference')
      .delete()
      .eq('id', channelId)
    if (error) throw error
  }

  async createChannel(
    data: ChannelPreferenceCreateDTO,
  ): Promise<ChannelPreference> {
    // Step 1: update details if exists
    let detailUpdateDTO: any = {}
    let details: any = data.details

    Object.keys(details).forEach((key: string) => {
      if (details[key] !== undefined) detailUpdateDTO[key] = details[key]
    })

    const { data: createResponse, error: createError }: any & PostgrestError =
      await this.supabase
        .from('channelPreference')
        .insert(detailUpdateDTO as never)
        .select('*, channelIncidentTypes:channelIncidentType(*)')

    if (createError) throw createError
    const channelPreference = createResponse[0] as unknown as ChannelPreference

    const incidentTypes = await this.createChannelIncidentTypes(
      channelPreference.id!,
      data.incidentTypeIds ?? [],
    )

    return {
      ...channelPreference,
      incidentTypes,
    } as ChannelPreference
  }

  async incidents(groupId: string): Promise<Incident[]> {
    const { data: viewResult, error: viewError } = await this.supabase
      .from('vwGroupNotificationSummary')
      .select()
      .eq('groupId', groupId)
      .order('createdAt', { ascending: false })

    if (viewError) throw viewError

    const records = viewResult as GroupNotificationSummary[]

    const incidentIds: number[] = records.map(
      (record: GroupNotificationSummary) => record.incidentId,
    )

    const { data: incidentResult, error: incidentError } = await this.supabase
      .from('incident')
      .select(fullIncidentQuery)
      .in('id', incidentIds)

    if (incidentError) throw incidentError

    return incidentResult as Incident[]
  }
}
